
import { Component, Vue } from 'vue-property-decorator'
import MaintenanceRecord from '@/components/maintenanceRecord/Index.vue'
import Echarts from 'echarts'
import { colorRandom } from '@/utils/formatData'

@Component({
  name: 'Record',
  components: { MaintenanceRecord }
})
export default class extends Vue {
  private pieEcharts: any = null
  private lineEcharts: any = null

  created () {
    this.getData()
  }

  getData () {
    this.$axios.get(this.$apis.record.statisticsMaintain, {
      objectId: this.$route.params.seedlingId
    }).then((res) => {
      this.getEchartsPie(res.pieChart)
      this.getEchartsLine(res.columnChart)
    })
  }

  // 饼图
  getEchartsPie (pieChart: any) {
    const { data, total } = this.handlePieChart(pieChart)
    const color = this.getPieColor(data)
    let lastPer = ''
    this.$nextTick(() => {
      this.pieEcharts = Echarts.init(this.$refs.pieEcharts as any)
      let num = 0
      const option = {
        color: color,
        tooltip: {
          show: true
        },
        legend: {
          type: 'scroll',
          itemGap: 26,
          itemWidth: 8,
          icon: 'circle',
          orient: 'vertical',
          left: 310,
          top: 'center',
          textStyle: {
            rich: {
              name: {
                color: '#666666FF'
              },
              value: {
                color: '#999999FF'
              },
              division: {
                color: '#D9D9D9FF'
              }
            }
          },
          formatter: function (name: string) {
            if (data && data.length) {
              for (let i = 0; i < data.length; i++) {
                if (name === data[i].name) {
                  let itemNum
                  if (total === 0) {
                    itemNum = 0
                  } else {
                    itemNum = data[i].value ? (data[i].value / total * 100).toFixed(2) : '0.00'
                    if (i < data.length - 1) {
                      num += Number(itemNum)
                    } else {
                      if (+itemNum !== 0) {
                        if (!lastPer) {
                          lastPer = (100 - num).toFixed(2)
                        }
                        itemNum = lastPer
                      }
                    }
                  }
                  return (
                    `{name| ${name}}{division|\t\t|} {value|\t${itemNum}%}`
                  )
                }
              }
            }
          }
        },
        series: [{
          name: '养护类型',
          type: 'pie',
          center: ['30%', '50%'],
          radius: ['60%', '80%'],
          itemStyle: {
            borderWidth: 2, // 设置border的宽度有多大
            borderColor: '#fff'
          },
          label: {
            show: false
          },
          labelLine: {
            show: false
          },
          data: data
        }]
      }
      this.pieEcharts.setOption(option)
    })
  }

  // 养护类型数据
  handlePieChart (pieChart: any) {
    const info: {data: Array<{name: string; value: number}>; total: number} = {
      data: [],
      total: 0
    }
    for (const i in pieChart) {
      info.total += +pieChart[i]
      info.data.push({
        value: +pieChart[i],
        name: i
      })
    }
    return info
  }

  // 获取饼图颜色
  getPieColor (data: any) {
    const color: Array<string> = ['#47A2FF', '#1AD1B0', '#FBD44C', '#F3657C', '#9760E5']
    if (data.length > 5) {
      new Array(data.length - 5).fill(1).forEach(() => {
        color.push(colorRandom())
      })
    }
    return color
  }

  // 折线
  getEchartsLine (data: any) {
    this.$nextTick(() => {
      this.lineEcharts = Echarts.init(this.$refs.lineEcharts as any)
      const option = {
        grid: {
          top: '10%',
          left: 0,
          right: 0,
          bottom: 0,
          show: true,
          borderWidth: '0',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          data: data.months,
          axisTick: {
            alignWithLabel: true,
            lineStyle: {
              color: '#00000026'
            }
          },
          axisLine: {
            lineStyle: {
              color: '#00000073'
            }
          },
          axisLabel: {
            margin: 12
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: '#00000026'
            }
          },
          axisLabel: {
            color: '#00000073'
          }
        },
        series: [
          {
            name: '养护记录', // ---系列名称
            type: 'bar', // ---类型
            legendHoverLink: true, // ---是否启用图例 hover 时的联
            itemStyle: { // ---图形形状
              color: '#1AD1B0'
            },
            barWidth: '15', // ---柱形宽度 // ---柱形间距
            data: data.count
          }
        ]
      }
      this.lineEcharts.setOption(option)
    })
  }
}
